import React, { useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTrash} from '@fortawesome/free-solid-svg-icons';

let Menu_c=()=>{
    let [menuItems,setmenu] = useState([
        {
            id: 1,
            food_name: "Cheese Burger",
            food_description: "A delicious cheese burger with lettuce, tomato, and a side of fries.",
            food_price: 150,
            food_image: "https://img.freepik.com/premium-photo/amazing-delicious-cheese-burger_727939-299.jpg"
        },
        {
            id: 2,
            food_name: "Veggie Pizza",
            food_description: "A tasty pizza topped with a variety of fresh vegetables and cheese.",
            food_price: 250,
            food_image: "https://www.twopeasandtheirpod.com/wp-content/uploads/2021/03/Veggie-Pizza-8.jpg"
        },
        {
            id: 3,
            food_name: "Pasta Alfredo",
            food_description: "Creamy Alfredo pasta served with garlic bread.",
            food_price: 200,
            food_image: "https://img.freepik.com/free-photo/plate-pasta-alfredo_74190-4819.jpg"
        },
        {
            id: 4,
            food_name: "Caesar Salad",
            food_description: "Fresh romaine lettuce with Caesar dressing, croutons, and Parmesan cheese.",
            food_price: 120,
            food_image: "https://img.freepik.com/free-photo/caesar-salad-top-view_2829-11046.jpg"
        },
        {
            id: 5,
            food_name: "Chicken Wrap",
            food_description: "Grilled chicken wrapped in a soft tortilla with fresh veggies and sauce.",
            food_price: 180,
            food_image: "https://img.freepik.com/free-photo/chicken-wrap-sandwich_144627-25273.jpg"
        },
        {
            id: 6,
            food_name: "Paneer Tikka",
            food_description: "Spicy and flavorful paneer tikka served with mint chutney.",
            food_price: 220,
            food_image: "https://img.freepik.com/premium-photo/indian-cuisine-paneer-tikka-served-plate-with-lemon-slice-cilantro-mint-sauce_466689-71658.jpg"
        },
        {
            id: 7,
            food_name: "Butter Chicken",
            food_description: "Rich and creamy butter chicken served with naan or rice.",
            food_price: 300,
            food_image: "https://img.freepik.com/free-photo/butter-chicken-curry_74190-4970.jpg"
        },
        {
            id: 8,
            food_name: "Fish Fry",
            food_description: "Crispy fried fish served with a side of tartar sauce.",
            food_price: 250,
            food_image: "https://img.freepik.com/premium-photo/fish-fry_466689-60438.jpg"
        }
    ]);
    
    let deletefood=(id)=>{
        
        let x=menuItems.filter((e)=>{
            return e.id!=id;
        })
        
        setmenu(x);
    }
    let sortarr=()=>{
        let k = [...menuItems].sort((a, b) => a.food_price - b.food_price);
        setmenu(k);
    }
  
    return(
        <div className="Menu">
                <button onClick={()=>{sortarr()}} >SORT</button>    
            <div className="Menu_container">
            
                {
                    menuItems.map((data,ty)=>{
                        return(
                            <div key={ty}   className="Menu_card_container" >
                        <img src={data.food_image}/>
                        
                        <h4 id="`food_n`" >{data.food_name}</h4>
                        <p>{data.food_description}</p>
                        <p  className="price_rs" >₹{data.food_price}</p>
                       {/* <button onClick={()=>{alert(ty)}} >{data.food_name}</button> */}
                       <FontAwesomeIcon onClick={()=>{deletefood(data.id)}} className="delicon"  icon={faTrash} />
                        
                          
                </div>
                        );
                        
                    })
                }
            </div>
        </div>
    );
}

export default Menu_c;